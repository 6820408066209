<template>
  <div>
    <template v-for="(item, index) in data">
      <el-submenu
          :key="index"
          :index="item.path"
          v-if="item.children.length > 0">
        <template slot="title">
          <div style="display: flex;width: 140px">
            <div><i :class="item.icon" class="icon-color"></i></div>
            <div style="margin-top: 1px">{{ item.name }}</div>
          </div>
          <span></span>
        </template>
        <son-menu :data="item.children"></son-menu>
      </el-submenu>
      <el-menu-item :key="index" v-else :index="item.path">
        <div style="display: flex;width: 140px">
          <div><i :class="item.icon" class="icon-color"></i></div>
          <div style="margin-top: 1px">{{ item.name }}</div>
        </div>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: "sonMenu",
  props: {
    data: {
      type: Array,
    },
  },
};
</script>
<style scoped>
.icon-color{
  margin: auto 0;
  font-size: 16px;
  color: #898c90;
  padding-right: 6px;
}
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}
::v-deep .el-menu-item:hover, ::v-deep .el-submenu__title:hover {
  background-color: #ffeded !important; /* 设置悬停背景色 */
  border-radius: 12px;
}
.el-menu-item.is-active {
  background-color: #ff4d4f !important;
  border-radius: 12px;
}
.el-aside {
  color: #333;
}

</style>