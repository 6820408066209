<template>
  <div class="header">
    <el-dropdown>
      <div class="my">
        <div style="display: flex;align-items: center">
          <el-avatar shape="square" size="medium" src="https://codeimg.yanhuotiyu.com//picture//2024/6/20/20240620164308zhangtenghui.jpg..jpg"></el-avatar>
          <div style="margin-left: 8px;font-weight: bold">太多</div>
          <div class="el-icon-arrow-down el-icon--right"></div>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <span class="menu-icon el-icon-unlock"></span>
          修改密码
        </el-dropdown-item>
        <el-dropdown-item>
          <span class="menu-icon el-icon-monitor"></span>
          退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import '/src/assets/styles.css';
export default {
  name: "Header"
}
</script>

<style scoped>
.header{
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
}
.my{
  border: none;
  padding: 6px 12px 6px 8px;
}
.my:hover{
  background-color: #F5F7FA;
  border-radius: 7px;
}
.menu-icon{
  color: red;
  font-weight: bold;
  margin-right: 4px;
}
</style>