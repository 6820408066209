<template>
  <div class="background">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "main",
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.background{
  height: 92%;
  padding: 4px 16px;
  background-color: white;
  overflow: auto;
}
</style>