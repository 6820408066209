<template>
  <el-container style="height: 100vh;overflow:auto">
    <el-aside width="240px" style="text-align: center;">
      <aside-page/>
    </el-aside>
    <el-container>
      <el-header style="background-color: white">
        <header-page/>
      </el-header>
      <el-main class="background">
        <div style="height: 14px;background-color: white;width: 100%;border-radius: 10px 10px 0 0"></div>
        <main-page/>
        <div style="height: 14px;background-color: white;width: 100%;border-radius: 0 0 10px 10px"></div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import MainPage from './components/main/Main.vue'
import HeaderPage from './components/header/Header.vue'
import AsidePage from './components/aside/Aside.vue'
export default {
  name: "home",
  components: { MainPage,HeaderPage,AsidePage },
  data() {
    return {}
  }
}
</script>

<style>
.background{
  background-color:#FAFAFA
}
</style>
<style scoped>

</style>