<template>
  <div>
    <el-image style="width: 150px; height: 80px" fit="scale-down"
              :src="require('/src/assets/image/logo.png')" ></el-image>
    <el-menu class="menu" router
         active-text-color="white"
        :default-active="activeIndex">
      <son-menu :data="menuArr"/>
    </el-menu>
  </div>
</template>

<script>
import SonMenu from './SonMenu.vue'
export default {
  components: {SonMenu},
  name: "Aside",
  data() {
    return {
      activeIndex: this.$route.path,
      menuArr: [
        {
          name: "首页",
          path: "首页",
          icon: "el-icon-monitor",
          children: [],
        },
        {
          name: "通讯录",
          path: "通讯录",
          icon: "el-icon-notebook-1",
          children: [
            {
              name: "成员管理",
              path: "/admin/contacts/user",
              icon: "el-icon-user",
              children: [],
            },
            {
              name: "部门管理",
              path: "/admin/contacts/dept",
              icon: "el-icon-folder-opened",
              children: [],
            },
            {
              name: "角色管理",
              path: "/admin/contacts/role",
              icon: "el-icon-postcard",
              children: [],
            },
          ],
        },
        {
          name: "考勤打卡",
          path: "考勤打卡",
          icon: "el-icon-place",
          children: [
            {
              name: "排班表",
              path: "/admin/attendance/scheduling",
              icon: "el-icon-date",
              children: [],
            },
            {
              name: "考勤组",
              path: "/admin/attendance/attendanceGroup",
              icon: "el-icon-coin",
              children: [],
            },
            {
              name: "考勤规则",
              path: "考勤规则",
              icon: "el-icon-setting",
              children: [
                {
                  name: "补卡规则",
                  path: "/admin/attendance/attendanceRules/repairCard",
                  icon: "",
                  children: [],
                },
              ],
            },
            {
              name: "班次管理",
              path: "/admin/attendance/classes",
              icon: "el-icon-c-scale-to-original",
              children: [],
            },
            {
              name: "假期管理",
              path: "/admin/attendance/holiday",
              icon: "el-icon-coffee-cup",
              children: [],
            },
          ],
        },
        {
          name: "OA管理",
          path: "OA管理",
          icon: "el-icon-cpu",
          children: [
            {
              name: "表单管理",
              path: "/admin/form/formsPanel",
              icon: "el-icon-setting",
              children: [],
            },
            {
              name: "钉钉历史表单",
              path: "/admin/form/pastRecords",
              icon: "el-icon-setting",
              children: [],
            },
          ],
        },
      ]
    }
  },
  methods:{
    to() {
      this.$router.push({
        path: "workspace/approval"
      })
    }
  }
}
</script>
<style>
/* 修改滚动条的宽度 */
::-webkit-scrollbar {
  width: 6px; /* 纵向滚动条的宽度 */
  height: 12px; /* 横向滚动条的高度 */
}

/* 滚动条轨道部分 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* 滚动条滑块部分 */
::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;
  border-radius: 10px;
}

/* 鼠标悬停在滑块上时 */
::-webkit-scrollbar-thumb:hover {
  background-color: #cbcbcb;
}
</style>
<style scoped>
.menu{
  padding: 0 20px 20px 20px;
  border: none
}
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}
::v-deep .el-menu-item:hover, ::v-deep .el-submenu__title:hover {
  background-color: #ffeded !important; /* 设置悬停背景色 */
  border-radius: 12px;
}

.el-menu-item.is-active {
  background-color: #ff4d4f !important;
  border-radius: 12px;
}
.el-aside {
  color: #333;
}

</style>